<template>
  <div class="alert-container clear V-alignment">
    <i :class="['iconfont', iconClz]"></i>
    <XText fw="900" ta="center" s="1rem" lh="1.5" mg="16px 10px 10px 10px" >{{ text }}</XText>
    <div class="H-alignment alert-footer">
      <el-button type="primary" @click="ok">{{ confirmText }}</el-button>
      <el-button type="danger" plain @click="cancel">{{ cancelText }}</el-button>
    </div>
  </div>
</template>

<script>
  import XText  from 'components/common/XText'

  export default {
    name: 'XAlert',
    components: { XText },
    props: {
      iconClz: String,
      text: String,
      confirmText: String,
      cancelText: String,
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      ok() {
        this.$emit('event-alert-ok', {})
      },

      cancel() {
        this.$emit('event-alert-cancel', {})
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alert-container {
    width: 100%;
    height: auto;
  }

  i {
    font-size: 6rem;
    color: yellow;
    margin-top: -30px;
  }

  .alert-footer {
    margin-top: 14px;
    width: 100%;
    justify-content: space-between;
  }

  .el-button {
    width: 47%;
    font-size: 1rem;
    font-weight: 900;
  }
</style>