<template>
  <div class="code-container V-alignment clear" @click="followOfficialAcc">
    <img src="../../assets/icon/icon-official-account.jpg" alt="" />
    <XText c="#3E3E3E" s=".8rem" fw="500" mg="6px 0 0 0">
      打开微信搜索 <span style="color: #409EFF; font-weight: bolder;">为智心理</span> 点长按识别直达
    </XText>
  </div>
</template>

<script>
  import XText  from 'components/common/XText'

  export default {
    name: 'OfficialAcc',
    components: { XText },
    props: {
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      followOfficialAcc() {
        window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkzOTQzMTEzMQ=="
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .code-container {
    position: relative;
  }

  .code-container > img {
    width: 90%;
    margin-top: -24px;
  }
</style>
