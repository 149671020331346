<template>
  <div class="page-container clear">
    <Header pad="30px 20px 40px 20px" style="height: 35vh" v-if="showUI">
      <div class="header-content H-alignment">
        <ProgressBar :cNum="curQ + 1" :totalNum="q.questionNum"/>
      </div>
    </Header>
    <!-- main -->
    <div class="main-content V-alignment">
      <XText fw="900" s="1.18rem" mg="6px 0 30px 0" ta="left" lh="1.5">
        {{qItems[curQ].titleIndex}}. {{qItems[curQ].title}}
      </XText>

      <!-- options list -->
      <div v-for="item in qItems[curQ].optionList" class="V-alignment option-item" @click="chooseAnswer(item)">
        <XText bkg="#F5F5F5" fw="400" s="1.1rem" pad="14px 12px" br="10px" mg="0 0 16px 0" ta="left"
               style="width: calc(100vw - 80px)" v-if="!qAns[curQ] || qAns[curQ].questionOptionId != item.questionOptionId">
          {{item.optionDesc}}. {{item.optionName}}
        </XText>

        <XText bkg="#40BFFF" c="#FFF" fw="900" s="1.1rem" pad="14px 12px" br="10px" mg="0 0 16px 0" ta="left"
               style="width: calc(100vw - 80px)" v-else>
          {{item.optionDesc}}. {{item.optionName}}
        </XText>
      </div>
    </div>
    <el-button type="primary" round @click="prevQuestion">上一题</el-button>

    <!-- official Account -->
    <XText bkg="#FFF" fw="400" s=".8rem" pad="10px 20px" br="10px" mg="20px 0 0 0" ta="left" lh="1.6"
           style="width: calc(100% - 40px)" v-if="showUI">
      我们将根据您的答题数据进行维度分析、并给出专业的分析授体验更多功能、或遇到测试中的故障问题需要联系客服请<span style="font-weight: 900; color: #409EFF" @click="showAccDialog = true">点击此处关注公众号</span>
    </XText>

    <!-- shadow dialog -->
    <el-dialog
      :visible.sync="showAccDialog"
      width="80%"
      center>
      <span slot="title" style="text-align: center; display: block; font-weight: 400; font-size: 1.125rem; padding: 0 0 6px 0">
        关注为智心理公众号
      </span>
      <AccCode />
    </el-dialog>

    <el-dialog
      :visible.sync="showCmtDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="75%"
      center>
      <!-- <span slot="title" style="text-align: center; display: block; font-weight: 400; font-size: 1.125rem; padding: 0 0 6px 0">
        提示信息
      </span> -->
      <XAlert text="您已完成全部测试， 是否进行答题提交？" 
              confirmText="立即提交" 
              cancelText="放弃" 
              iconClz="icon-duigou"
              @event-alert-ok="submitAnswer" 
              @event-alert-cancel="abortAnswer"
              />
    </el-dialog>
  </div>
</template>

<script>

  // @api
  import { getQuestionInfo, getQuestionItems } from 'api/q'

  // @components
  import Header      from 'components/common/Header'
  import XText       from 'components/common/XText'
  import XAlert      from 'components/common/XAlert'
  import AccCode     from 'components/biz/AccCode'
  import ProgressBar from 'components/common/ProgressBar'

  export default {
    name: 'QItem',
    components: {
      Header, XText, ProgressBar, AccCode, XAlert
    },
    async mounted() {
      const res = await getQuestionInfo(this.$route.query.pid);
      this.q = res.resData

      this.loadQuestionItems()
    },
    methods: {
      async loadQuestionItems() {
        const loading = this.$loading({ lock: true, text: '', spinner: '', background: 'rgba(0, 0, 0, 0.1)' });
        const res = await getQuestionItems(this.q.id, 0, this.q.questionNum);
        this.qItems = res.resData.content

        // console.log(this.qItems)
        this.showUI = true
        loading.close()
      },

      chooseAnswer(e) {
        if(!this.qAns[this.curQ]) {
          this.qAns.push( e )
        } else {
          this.qAns[this.curQ] = e
          this.$forceUpdate()
        }

        if(this.curQ == this.q.questionNum - 1) {
          this.showCmtDialog = true
          // console.log(this.qAns, this.qAns.length)
        }
        
        setTimeout(() => { this.makeUserAnswer(e) }, 300)
      },

      async makeUserAnswer(e) {
        this.curQ < this.q.questionNum - 1 ? this.curQ++ : '';
        // console.log(this.curQ, this.q.questionNum)
      },

      prevQuestion() {
        if(this.curQ > 0) this.curQ --;
      },

      async submitAnswer() {
        this.$router.push({ name: 'QPay', query: { pid: this.$route.query.pid }});
      },

      abortAnswer() {
        this.$router.push({ name: 'Home', query: { }});
        this.showAlertDialog = false
      }
    },
    computed: {
    },
    data() {
      return {
        // ui
        showDialog: false,
        showAccDialog: false,
        showAlertDialog: false,
        showNotPaidDialog: false,
        showCmtDialog: false,
        showUI: false,

        // order
        curOrder: {},
        // data
        scene: { },
        q: { },
        curQ: 0,  // current question
        qItems: [{ titleIndex: '', title: '' }],
        qAns: [],
        // errAns: [],
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-container {
    background: #F2F2F2;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .header-content {
    position: relative;
    z-index: 100;
    width: 100%;
  }

  .main-content {
    position: relative;
    width: calc(100vw - 40px);
    margin-left: 20px;
    background: #FFF;
    z-index: 100;
    margin-top: calc(-35vh + 64px);
    border-radius: 10px;
    align-items: flex-start;
    padding: 20px 20px 10px 20px;
  }

  .option-item {
    position: relative;
    width: 100%;
  }

  .option-item > i {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 16px;
    color: yellow;
  }

  .el-button {
    width: 40%;
    margin-top: 24px;
    font-size: 1rem;
    font-weight: 900;
    border-radius: 10px;
  }

  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-dialog .el-dialog__header {
    text-align: left;
    font-weight: 900
  }

  /deep/ .el-dialog--center .el-dialog__body {
    padding: 20px;
  }

  /*  css for media query */
  @media screen and (min-width: 600px) {
    .main-content {
      grid-template-columns: repeat(3, calc(33.3333% - 8px));
    }

    /deep/ .el-carousel {
      height: 30vw !important;
    }

    /deep/ .el-dialog {
      width: 50% !important;
    }
  }

  @media screen and (min-width: 900px) {
    .main-content {
      grid-template-columns: repeat(4, calc(25% - 8px));
    }

    /deep/ .el-carousel {
      height: 30vw !important;
    }

    /deep/ .el-dialog {
      width: 40% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .main-content {
      grid-template-columns: repeat(5, calc(20% - 8px));
    }

    /deep/ .el-carousel {
      height: 23vw !important;
    }

    /deep/ .el-dialog {
      width: 20% !important;
    }
  }
</style>
