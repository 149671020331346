<template>
  <div class="pb-container H-alignment clear" style="justify-content: space-between;">
    <div class="outer">
      <div class="inner base-color" :style="{'width': (cNum * 100 / totalNum) + '%'}"></div>
    </div>
    <XText c="#FFF" fw="900" s=".85rem">{{cNum}} / {{totalNum}} 题</XText>
  </div>
</template>

<script>
  import XText  from 'components/common/XText'

  export default {
    name: 'ProgressBar',
    components: { XText },
    props: { 
      cNum: Number,
      totalNum: Number,
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pb-container {
    width: 100%;
    height: auto;
  }

  .outer {
    width: calc(100% - 90px);
    height: 18px;
    background: #FFF;
    padding: 3px;
    z-index: 10;
    border-radius: 30px;
  }

  .inner {
    height: 100%;
    border-radius: 30px;
  }
</style>
